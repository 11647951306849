.header {
    background-color: #f8f9fa;

}

.title {
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 20px !important;
}

.close {
    color: rgb(0, 0, 0);

    &:hover {
        color: rgb(255, 255, 255);
        background-color: #000;
    }
}

.label {
    color: #2c2c2c;
    font-size: 16.1px;
}

.bgContainer {
    background-color: #ff008a;
    padding: 7px;
    border-radius: 5px;
}

.secondBgColor {
    background-color: #3eaadd;
}

.leaveBgColor {
    background-color: #f89b29;
}

.holidayBgColor {
    background-color: #d90303a8;
}

.absentBgColor {
    background-color: #b73a4d;
}